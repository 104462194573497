// src/App.js
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import Properties from "scenes/Properties";
import Tenants from "scenes/Tenants"
import Users from "scenes/Users"
import Tickets from "scenes/Tickets";
import Payments from "scenes/Payments";
import Dashboard from "scenes/Dashboard";
import Landlords from "scenes/Landlords";
import Brokers from "scenes/Brokers";
import Manager from "scenes/Managers/Managers";
import PropertiesManaged from "scenes/PropertiesManaged.jsx/index.jsx";
import ReferredLandlords from "scenes/Managers/ReferredLandlords";
import LandlordDetails from "scenes/Landlords/LandlordDetails"; // Ensure correct path
import NotFound from "scenes/404/Notfound";
import LandlordExpenses from "scenes/LandlordExpenses";
import Units from "components/Tables/UnitsTable";
import PropertyDetails from "scenes/Properties/propertyDetails";
import BrokerDetails from "scenes/Brokers/BrokerDetails"
import TenantDetails from "scenes/Users/TenantDetails";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import TenantReceipt from "scenes/Managers/Receipt";
import BrokerPayments from "scenes/Brokers/BrokerPayments";
import LandlordTenants from "scenes/Landlords/LandlordTenants";
import Booking from "scenes/Booking";
import BookingDetails from "scenes/Booking/BookingDetails";
import LandlordTenantsPayments from "scenes/Landlords/LandlordTenantsPayments";
import LoanRequests from "scenes/TenantsLoans";
import LoanRequestsTable from "components/Tables/LoanRequestsTable";
import LoanReview from "scenes/TenantsLoans/LoanReview";
import UnitDetails from "scenes/Properties/unitDetails";
import RegisteredProperties from "scenes/Managers/registeredProperties";
import Sidebar from "scenes/global/NewSidebar";
import Navbar from "scenes/navbar";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [isSidebar, setIsSidebar] = useState(true);
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {isAuth ? (
            <>
              <Navbar setIsSidebar={setIsSidebar} />
              <Box sx={{ display: "flex" }}>
                <Sidebar isSidebar={isSidebar} />
                <main className="content">
                  <Box>
                    <Routes>
                      <Route path="/home" element={<Dashboard />} />
                      <Route path="/tenants" element={<Users />} />
                      <Route path="/saving-tenants" element={<Tenants />} />
                      <Route path="/payments" element={<Payments />} />
                      <Route path="/properties" element={<Properties />} />
                      <Route path="/units" element={<Units />} />
                      <Route path="/details" element={<PropertyDetails />} />
                      <Route path="/broker-details" element={<BrokerDetails />} />
                      <Route path="/payments" element={<Payments />} />
                      <Route path="/landlords" element={<Landlords />} />
                      <Route path="/landlord-details/:id" element={<LandlordDetails />} />
                      <Route path="/brokers" element={<Brokers />} />
                      <Route path="/landlord-expenses" element={<LandlordExpenses />} />
                      <Route path="/managers" element={<Manager />} />
                      <Route path="/referred-landlords" element={<ReferredLandlords />} />
                      <Route path="/properties-managed" element={<PropertiesManaged />} />
                      <Route path="/broker-payments" element={<BrokerPayments />} />
                      <Route path="/Landlord-tenants" element={<LandlordTenants />} />
                      <Route path="/TenantDetails" element={<TenantDetails />} />
                      <Route path="/tenants-loans" element={<LoanRequests />} />
                      <Route path="/tenant-payments" element={<LandlordTenantsPayments />} />
                      <Route path="/tenant-receipts" element={<TenantReceipt />} />
                      <Route path="/bookings" element={<Booking />} />
                      <Route path="/booking-details" element={<BookingDetails />} />
                      <Route path="/loan-review" element={<LoanReview />} />
                      <Route path="/units/details" element={<UnitDetails />} />
                      <Route path="/registered-properties" element={<RegisteredProperties />} />
                      <Route path="/profile/:userId" element={<ProfilePage />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Box>
                </main>
              </Box>
            </>
          ) : (
            <main className="content">
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<LoginPage />} />
              </Routes>
            </main>
          )}
          {/* <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route
              path="/home"
              element={isAuth ? <Dashboard /> : <Navigate to="/" />}
            />
            <Route
              path="/tenants"
              element={isAuth ? <Users /> : <Navigate to="/" />}
            />
            <Route
              path="/saving-tenants"
              element={isAuth ? <Tenants /> : <Navigate to="/" />}
            />
            <Route
              path="/payments"
              element={isAuth ? <Payments /> : <Navigate to="/" />}
            />
            <Route
              path="/properties"
              element={isAuth ? <Properties /> : <Navigate to="/" />}
            />
            <Route
              path="/units"
              element={isAuth ? <Units /> : <Navigate to="/" />}
            />
            <Route
              path="/details"
              element={isAuth ? <PropertyDetails /> : <Navigate to="/" />}
            />
            <Route path="/broker-details" element={<BrokerDetails />} />
            <Route
              path="/schedules"
              element={isAuth ? <Tickets /> : <Navigate to="/" />}
            />
            <Route
              path="/profile/:userId"
              element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
            />
            <Route
              path="/landlords"
              element={isAuth ? <Landlords /> : <Navigate to="/" />}
            />
            <Route
              path="/brokers"
              element={isAuth ? <Brokers /> : <Navigate to="/" />}
            />
            <Route
              path="/landlord-details/:id"
              element={isAuth ? <LandlordDetails /> : <Navigate to="/" />}
            />
            <Route
              path="/TenantDetails"
              element={isAuth ? <TenantDetails /> : <Navigate to="/" />}
            />

            <Route
              path="/landlord-expenses"
              element={isAuth ? <LandlordExpenses /> : <Navigate to="/" />}
            />
            <Route
              path="/manager"
              element={isAuth ? <Manager /> : <Navigate to="/" />}
            />
            <Route
              path={`/properties-managed`}
              element={isAuth ? <PropertiesManaged /> : <Navigate to="/" />}
            />
            <Route
              path="/broker-payments"
              element={isAuth ? <BrokerPayments /> : <Navigate to="/" />}
            />
            <Route
              path="/Landlord-tenants"
              element={isAuth ? <LandlordTenants /> : <Navigate to="/" />}
            />
            <Route
              path="/tenants-loans"
              element={isAuth ? <LoanRequests /> : <Navigate to="/" />}
            />
            <Route
              path="/tenant-payments"
              element={isAuth ? <LandlordTenantsPayments /> : <Navigate to="/" />}
            />
            <Route
              path="/tenant-receipts"
              element={isAuth ? <TenantReceipt /> : <Navigate to="/" />}
            />
            <Route
              path="/bookings"
              element={isAuth ? <Booking /> : <Navigate to="/" />}
            />
            <Route
              path="/booking-details"
              element={isAuth ? <BookingDetails /> : <Navigate to="/" />}
            />
            <Route
              path="/loan-review"
              element={isAuth ? <LoanReview /> : <Navigate to="/" />}
            />
            <Route
              path="/units/details"
              element={isAuth ? <UnitDetails /> : <Navigate to="/" />}
            />

            <Route path="/manager-details/:id" element={<ManagerDetails />} />
          </Routes> */}
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
